@use 'tools' as *;
@use 'tools/variables' as var;
@use 'tools/mixins/media-queries' as mq;

// Accordion

%_accordion-nojs-title {
  background-color: var.$bg-gray;
  cursor: default;

  &::after {
    content: normal !important;
  }

  &:hover {
    background-color: var.$bg-gray !important;
  }
}

.accordion {
  & > * {
    display: block !important;
  }

  @for $i from 2 through 6 {
    & > h#{$i}:first-child {
      &, & ~ h#{$i}:not(.literal):not([data-literal]) {
        @extend %_accordion-nojs-title;
      }
    }
  }

  &.accordion:not([data-no-expand-all]):not(.off) {
    margin-top: 1rem;
  }
}

.dk-accdn .acc-ctrl {
  display: none;
}

.dk-accdn .acc-title button {
  @extend %_accordion-nojs-title;
}

.dk-accdn .acc-content {
  display: block !important;
}

// Expandable text

.expandable {
  &::before {
    content: normal !important;
  }

  & > * {
    display: block !important;
  }
}

// Foldable text

%foldable-container {
  .fold ~ * {
    display: block !important;
  }

  .fold, .folded {
    display: none !important;
  }
}

.foldable, .box {
  @extend %foldable-container;
}

// Tab navigation

.dk-tabnav {
  .tn-drop-button {
    display: none;
  }

  & > ul {
    position: static;

    &::before {
      display: none;
    }

    &.tn-expandable > li > div button {
      display: none;
    }
  }

  @include mq.mobile-tablet {
    &:not(.tn-menu-open) > ul {
      display: block;
    }
  }
}

// 75th Anniversary hero banner
.home-hero {
  .hero-body {
    @media (prefers-reduced-motion) {
      animation-play-state: paused !important;
    }

    button {
      display: none;
    }

    .hex_M_img img:first-child {
      opacity: 1;
    }
  }
}

// Carousel component
.carousel {
  display: flex;
  flex-direction: column;

  &.cs-hidden {
    .carousel-slide{
      position: initial;

      &:not(:first-child) {
        visibility: visible !important;
      }
    }
  }

  button {
    display: none;
  }

  .carousel-slide {
    position: relative;

    &, *:not(img) {
      transform: none !important;
      top: initial !important;
      left: initial !important;
    }

    img {
      left: 50% !important;
      transform: translateX(-50%) !important;
      filter: none !important;
    }
  }

  .cs-dots {
    display: none;
  }
}
